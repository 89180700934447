<template>
  <div class="watch">
  <div style="margin: 0" class="row">
 <div class="col-12 col-lg-2 watch_sidebar">
<span style="padding-left: 1em"><i @click="$router.push('/dashboard')" style="cursor:pointer; " class="fas fa-chevron-circle-left"></i>
   VS: {{ this.event ? this.event.opponent : "loading..." }} </span><br />
    <div style="padding:15px">
    <select v-model="section" class="form-select form-select-sm" aria-label=".form-select-sm example">

  <option value="all">All Recordings {{ event ? "("+event.clips.length+")" : ""}}</option>
    <option value="search">Search by tag</option>

</select></div>
 <div v-if="section == 'search'" style="padding-left:1em">

Tag:
 <select v-model="search_tag" class="form-select form-select-sm" placeholder="name, QTR, scorer, ect" aria-label=".form-select-sm example">

  <option v-for="(n, index) in all_tags" v-bind:key="index" v-bind:value="n">{{ n}}</option>
 
</select>
Value: <input class="form-control form-control-sm" v-model="search_value" @input="searching" placeholder="tag value">
 <hr />

 </div>
    <div   class="sidebar_list" >
      <div v-if="event" style="text-align:left;">
        <div v-if="section == 'search'">
         <div v-for="(index) in search_result" v-bind:key="index" style="padding-left: 2em" @click="select_clip(index)" v-bind:style="index == clip_current ? 'background-color:grey' : ''" class="clip">
           #{{index + 1}} {{event.clips[index].tags.name}}
        </div> 
           </div>
        <div v-else>
        <div v-for="(n, index) in event.clips" v-bind:key="index" style="padding-left: 2em" @click="select_clip(index)" v-bind:style="index == clip_current ? 'background-color:grey' : ''" class="clip">
            #{{ index+1}} {{n.tags.name}} 
        </div> 
          <div v-if="can_edit" class="clip" style="padding-left: 2em" @click="(!clip_is_recording) ? (clip_is_recording = player.currentTime()) : (clip_create(clip_is_recording, player.currentTime()))" v-bind:style="clip_is_recording ? 'background-color:grey' : ''">
         <span v-if="!isMobileSafari"> {{ clip_is_recording ? "Click to End New Clip : "+(player.currentTime().toFixed(1) - clip_is_recording).toFixed(1)   : "+ New Sub-Clip"}}</span>     
          </div>
          <div v-if="can_edit" class="clip" @click="$router.push('/add/'+$route.params.event_id)" style="padding-left: 2em"> 
            + Add/Upload Recording
          </div>
         </div>
         
    </div>
    </div>
 </div>
 <div class="col-12 col-lg-8 ">
    
<div class="video_player">
       <video ref="videoPlayer"  crossorigin="anonymous" playsinline class="video-js vjs-fluid"></video>
</div>
 <div class="bar_tools">
     <span @click="canvas.isDrawingMode = true; markup_mode = 'draw'; $forceUpdate()" v-bind:class="canvas ? markup_mode == 'draw' ? 'tools_btn_active' : '' : '' " class="tools_btn ">
        <i class="fas fa-pen"></i>
     </span> |
   <span @click="canvas.isDrawingMode = false; markup_mode = 'circle'; $forceUpdate()"  v-bind:class="canvas ? markup_mode != 'circle' ? '' : 'tools_btn_active' : '' " class="tools_btn">
     <i class="far fa-circle"></i>
     </span> |
      <span @click="canvas.isDrawingMode = false; markup_mode = 'text'; $forceUpdate()"  v-bind:class="canvas ? markup_mode != 'text' ? '' : 'tools_btn_active' : '' " class="tools_btn">
     <i class="far fa-keyboard"></i>
     </span> |
      <span @click="canvas.isDrawingMode = false; markup_mode = 'move'; $forceUpdate()"  v-bind:class="canvas ? markup_mode != 'move' ? '' : 'tools_btn_active' : '' " class="tools_btn">
     <i class="far fa-arrows-alt"></i>
     </span> |
      <span @click="canvas.isDrawingMode = false; markup_mode = 'trash'; $forceUpdate()"  v-bind:class="canvas ? markup_mode != 'trash' ? '' : 'tools_btn_active' : '' " class="tools_btn">
     <i class="far fa-trash"></i>
     </span> 
<span v-if="can_edit" class="tools_btn" style="float:right">
 <span style="cursor:pointer" @click="event.clips.splice(clip_current, 1); server_update_event(); select_clip(clip_current)" v-if="can_edit"><i   class="fas fa-trash"></i></span> |
 <span v-if="!isMobileSafari">{{ clip_is_recording ? (player.currentTime().toFixed(1) - clip_is_recording).toFixed(1)  : "0.0"}} </span>     <i v-if="!clip_is_recording" class="fas fa-video" @click="clip_is_recording = player.currentTime()"></i> <i v-if="clip_is_recording"  @click="clip_create(clip_is_recording, player.currentTime());clip_is_recording = null" class="fas fa-video-slash"></i>
     </span>
     </div>
       <br />
       <div class="watch_clip_tags">
           <h5>Clip Tags</h5>

            <table class="tag_table" v-if="event && event.clips && event.clips[clip_current]">
  <tr>
    <th>Name</th>
    <th>Value</th>

  </tr>


  <tr v-for="(event2 ,index) in event.clips[clip_current].tags" v-bind:key="index" >
    <td>{{ index }} </td>
    <td> <span v-if="can_edit"><input v-if="event && event.clips " @blur="server_update_event()" v-model="event.clips[clip_current].tags[index]" placeholder="value"></span><span v-else> {{ event2}}</span></td>
    <td><span style="cursor:pointer" @click="delete event.clips[clip_current].tags[index]; server_update_event()" v-if="can_edit"><i v-if="index != 'name'"  class="fas fa-trash"></i></span></td>
  </tr>
  <tr  v-if="can_edit" >
     <td><input v-model="tag_add_name" placeholder="name">  <select v-model="tag_add_name" class="" placeholder="name, QTR, scorer, ect" aria-label=".form-select-sm example">

  <option v-for="(n, index) in all_tags" v-bind:key="index" v-bind:value="n">{{ n}}</option>
 
</select></td>
     <td><input v-model="tag_add_value" placeholder="name"> <button class="btn btn-dark" @click="createTag">+ Add Tag</button></td>
     
      </tr>
</table>
<br />

       </div>
       <br />
 </div>
  <div class="col-12 col-lg-2 sidebar_tools">
   
      <canvas v-if="includeImage" width="960" height="540" class="preview_note"  ref="preview_note"></canvas>
      <label v-if="includeImage">Message (Optional): </label>
          <textarea class="form-control chat_text"  v-model="add_note_content" placeholder="Enter Message..." rows="2"></textarea>
          <br />
          <input type="radio"  value="private" v-model="note_new_privacy"> <label for="private">Private</label>  <input type="radio" value="public" v-model="note_new_privacy"> <label for="two">Public</label> <input type="radio" value="staff" v-model="note_new_privacy"> <label for="two">Coaches Only</label><br />
<br>
          <button @click="add_note" class="btn btn-light">+ Add Note {{includeImage?"(Image)" :""}}</button>
          <hr />

<div class="chat_list p-1" style="overflow-y: auto; max-height: 800px;">
<div class="chat_message" v-for="(message ,index) in messages" v-bind:key="index">
  <span class="chat_author"> {{ message.author_name }} ({{ message.can_see.includes("public") ? "Public": message.can_see.length > 1 ? "Private-Staff" : "Private"}}) <span style="cursor:pointer" @click="delete_note(message._id, message)" v-if="message.author_uid == user.uid"><i   class="fas fa-trash"></i></span></span><br />
  <span class="chat_content"> <span v-if="message.image"><a target="_blank" v-bind:href="message.image.url">View Markup Image</a> <br /></span> {{ message.content }} </span>
</div>

</div>
 </div>
  </div>
  </div>
</template>
<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css'
require("../assets/videojs-offset.cjs.js");
require('videojs-hotkeys');
import { fabric } from "fabric";
import firebase from "firebase";
require("firebase/firestore");
var db = firebase.firestore();
// function isMobileSafari() {
//     return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
// }



export default {
    name: "VideoPlayer",
    props: {
  
    },
    data() {
        return {
            player: null,
            section: 'all',
            search_tag: "",
            search_value: "",
            search_result: [],
            clip_is_recording: null,
            canvas: null,
            tag_add_name: "",
            tag_add_value: "",
            clip_current: 0,
            event: undefined,
            team: undefined,
            can_edit: false,
            note_new_privacy: "private",
            add_note_content: "",
            includeImage: false,
            markup_setup: undefined,
            user: undefined,
            markup_mode: "draw",
            isMobileSafari: navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/),
            messages: [
             
            ],
            options: {
                autoplay: true,
                controls: true,
                muted: true,
                playbackRates: [0.1, 0.5, 1,2,4],
                "fluid": true,
                  controlBar: {
        children: [
            "playToggle",
              "PlaybackRateMenuButton",
            "volumeMenuButton",
            "durationDisplay",
            "timeDivider",
            "currentTimeDisplay",
            "progressControl",
            "remainingTimeDisplay",
            "fullscreenToggle",
            "volumeControl"
          
        ]
    },
                sources: [
                   
                ]
            }
        }
    },
    computed: {
      all_tags: function(){
       
       var list = []

       for (let index = 0; index < this.event.clips.length; index++) {
         const element = this.event.clips[index];
         list.push(...Object.keys(element.tags))
       }
       
      list = list.filter(function(item, pos) {
    return list.indexOf(item) == pos;
})
    
        return list
      }
    },
    methods: {
      async delete_note(id, message){
        if (message.image){
             var storageRef = firebase.storage().ref();
      
        var ref = storageRef.child('stills/'+firebase.auth().currentUser.uid+"/"+message.image.id+".png");
        ref.delete()
        }
               await db.collection("Events/"+this.$route.params.event_id+"/notes").doc(id).delete()
             },
      createNote(){
        if (this.isMobileSafari){ return; }
            this.includeImage = true
       setTimeout(function (){
         
  var ctx =  this.$refs.preview_note.getContext("2d")
    console.log(this.canvas)
         ctx.drawImage(this.$refs.videoPlayer,0,0, 960, 540)
           ctx.drawImage(this.canvas.lowerCanvasEl,0,0, 960, 510)


       
       }.bind(this), 100)
       
      },
     async add_note(){
        var can_see = [firebase.auth().currentUser.uid]

        if (this.note_new_privacy == "public"){
can_see.push("public")
        }

         if (this.note_new_privacy == "staff"){
can_see.push("staff")
        }
var noteT = {
                 author_uid: firebase.auth().currentUser.uid,
                author_name: firebase.auth().currentUser.displayName || firebase.auth().currentUser.email || firebase.auth().currentUser.phoneNumber,
                can_see: can_see,
                timestamp: (new Date().getTime()) / 1000,
                content: this.add_note_content
      }

      if (this.includeImage){
                     var loader = this.$loading.show({
                  // Optional parameters
                  container:  this.$refs.joinTeam,
          
                });
                try {
        var storageRef = firebase.storage().ref();
        var name = (new Date().getTime()) / 1000
        var ref = storageRef.child('stills/'+firebase.auth().currentUser.uid+"/"+name+".png");
        this.$refs.preview_note.toBlob(async function(blob) {

var snapshot = await ref.put(blob)
 
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
   var downloadURL = await snapshot.ref.getDownloadURL()
      noteT.image = {url: downloadURL, id: name, region: 1}
      console.log('File available at', downloadURL);
        db.collection("Events/"+this.$route.params.event_id+"/notes").add(noteT)
      this.add_note_content = ""
    this.includeImage = false
    loader.hide()
        }.bind(this))
                }catch(error){
                  loader.hide()
                }
return
      }


        db.collection("Events/"+this.$route.params.event_id+"/notes").add(noteT)
      this.add_note_content = ""
      },
      server_update_event(){
     db.collection("Events").doc(this.$route.params.event_id).update(this.event)
      },
      createTag: async function(){
        this.event.clips[this.clip_current].tags[this.tag_add_name] = this.tag_add_value
        this.$forceUpdate()
        this.server_update_event()
      },
      searching: async function(){
      // this.search_result = this.event.clips.filter(clip => clip.tags[this.search_tag]);

       this.search_result = this.event.clips.reduce(function(acc, clip, index) {
         console.log( clip, index)
  if (clip.tags[this.search_tag] && clip.tags[this.search_tag].toUpperCase() == this.search_value.toUpperCase()) {
    acc.push(index);
  }
  return acc;
}.bind(this), []);
       console.log( this.search_result)
      },
      resizeHandler: async function(){
        console.log(this.canvas)
         var canvasDiv = document.getElementById("canvasDiv")
       if (this.canvas && canvasDiv){
        
this.canvas.setHeight(canvasDiv.clientHeight);
    this.canvas.setWidth(canvasDiv.clientWidth);
   
    this.canvas.renderAll();
        }
      },
      setup_notes: async function(){
var can_see = ["public", firebase.auth().currentUser.uid]
if (this.can_edit){
  can_see.push( "staff")
}
    db.collection("Events/"+this.$route.params.event_id+"/notes").where("can_see", "array-contains-any", can_see  ).orderBy('timestamp', 'desc').limit(100)
    .onSnapshot((querySnapshot) => {
        this.messages = [];
        querySnapshot.forEach((doc) => {
            this.messages.push({...doc.data(), _id: doc.id});
        });
        console.log(querySnapshot)
      
    });

      },
        clip_create: async function(start, stop){
          if (stop < start) {  this.clip_is_recording = null ; return }
          var newClip = {
            source: this.event.clips[this.clip_current].source,
            tags: {name: "Clip"},
            start: start,
            end: stop
          }
          console.log(newClip)
          this.event.clips.push(
            newClip
          )
          this.clip_is_recording = null
          this.server_update_event()
        },
        select_clip: async function(index){
            if (this.event.clips.length <= index){
                index = 0
            }
            console.log(index)
            var clip = this.event.clips[index]
            this.clip_current = index
           
           if (this.player._offsetEnd){
           this.player.play()
                this.player._offsetEnd = 0
            this.player._offsetStart = 0
             this.player.currentTime(0)
           }
             
            
            this.player.pause()
 
            if (clip.source.type == "seasoncast"){

            this.player.src( {src: "https://dvr.bunny.seasoncast.com/file/mylivegame/recordings/"+clip.source.username+"/"+clip.source.recording_id+"/video.mp4"})

            }else{
              var storage = firebase.storage();
var storageRef = storage.ref();
              

            this.player.src( {src: await storageRef.child('clips/'+this.$route.params.event_id+"/"+clip.source.recording_id+".mp4").getDownloadURL()})


            }
            this.player._offsetEnd = undefined
            this.player._offsetStart = undefined
          this.player.currentTime(0)
            if (clip.start && clip.end){
    this.player.offset({
  start: clip.start,
  end: clip.end,
  restart_beginning: true
});

            }

this.player.play()
        },
        setup_player: async function(){
          if (!this.event.clips[0]){ return }
                 var clip = this.event.clips[0]
         
           if (clip.source.type == "seasoncast"){

            this.options.sources = [ {src: "https://dvr.bunny.seasoncast.com/file/mylivegame/recordings/"+clip.source.username+"/"+clip.source.recording_id+"/video.mp4"}]

            }else{
            this.options.sources = [ {src: "https://"}]

            }
  console.log( this.options.sources)
        this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
            console.log('onPlayerReady', this);
        
              this.select_clip(0)
 
        }.bind(this))
//        this.player.offset({
//   start: 10,
//   end: 15,
//   restart_beginning: true //Should the video go to the beginning when it ends
// });
var list = document.getElementsByClassName("video-js")
var i;
for (i = 0; i < list.length; i++) {
    var div = document.createElement('div');

div.style.position = "absolute"
div.style.top = 0
div.style.left = 0

div.style.right = 0
div.style.bottom = "3em"

div.style.display = "block"

div.id = "canvasDiv"
var canvas = document.createElement('canvas');
canvas.style.width = "100%"
canvas.style.height = "100%"


div.append(canvas)
  list[i].append(div)


// var triangle = new fabric.Triangle({
//   width: 20, height: 30, fill: 'blue', left: 50, top: 50
// });

var canvasA = new fabric.Canvas(canvas);
this.canvas = canvasA


 canvasA.isDrawingMode = true
 canvasA.freeDrawingBrush.color = "#f5f122E6"
 canvasA.freeDrawingBrush.width = 7
    canvasA.setHeight(div.clientHeight);
    canvasA.setWidth(div.clientWidth);
   
    canvasA.renderAll();


}

this.player.on('play', function(){
 
    this.canvas.clear()
 this.includeImage = false
}.bind(this));

this.player.on('ended', function(){

    this.clip_current += 1
this.select_clip(this.clip_current)
}.bind(this));
this.canvas.on('mouse:up', function(event){
 
 

  if (event.target == null && this.markup_mode == 'circle'){
   var newObject = new fabric.Circle({
  top:  this.markup_setup.y, left:  this.markup_setup.x, radius: Math.max(Math.abs(this.markup_setup.x - event.pointer.x), Math.abs(this.markup_setup.y - event.pointer.y), 40 )/ 2, stroke: 'white', fill: 'transparent',
    strokeWidth: 1
});

this.canvas.add(newObject)
  }

     this.createNote()

}.bind(this));
this.canvas.on('mouse:down', function(event){
 
    this.player.pause()

    if (event.target == null && this.markup_mode == 'circle'){
      this.markup_setup = {x:event.pointer.x, y: event.pointer.y}
  
    }
  
 if (event.target == null && this.markup_mode == 'text'){
     this.canvas.add(new fabric.IText('Tap and Change', { 
    left:event.pointer.x, //Take the block's position
    top: event.pointer.y, 
    fill: 'white',
    fontWeight: 'bold',
    fontSize: 30,
    fontFamily: "Arial",
   stroke: "black",
    strokeWidth: 1
}));

    }
     if (event.target != null && this.markup_mode == 'trash'){
       this.canvas.remove(this.canvas.getActiveObject());
     }
}.bind(this));
        }

    },
    created() {
  window.addEventListener("resize", this.resizeHandler);
},
destroyed() {
  window.removeEventListener("resize", this.resizeHandler);
},
    mounted: async function(){

 this.user = await  new Promise((resolve, reject) => {
     const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
     }, reject);
  });

  if (!this.user){
  this.$router.push('/login')
  }
      //add UI loading

    db.collection("Events").doc(this.$route.params.event_id).onSnapshot((doc) => {
       this.event =  doc.data()
       console.log("meta: ",doc.metadata)
       if (this.event.clips.length==0){
         this.$router.push('/add/'+this.$route.params.event_id)
       }
       if (!this.player){
           this.setup_player()   
       }

       if (!this.team){
            db.collection("Teams").doc(this.event.team_id).onSnapshot((doc1) => {
       this.team =  doc1.data()
       console.log("meta: ",doc1.metadata)
       if (this.team.can_edit.includes(firebase.auth().currentUser.uid)){
         this.can_edit = true
         
       }
       this.setup_notes()
    });
       }
    });



    
//  var doc = await docRef.get()

//     if (doc.exists) {
//         console.log("Document data:", doc.data());
//         this.event = doc.data()
//     } else {
//         // doc.data() will be undefined in this case
//         console.log("No such document!");
//     }

  


  
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    }
}
</script>
<style scoped>
.chat_text{
  width: 100%;
  background-color: transparent;
  color:white;
 
}
.chat_author{
 font-size: 0.7em;
 color: rgb(189, 189, 189);
}
.chat_message{
  text-align: left;
     word-wrap: break-word;
     
}
.watch_sidebar{
background-color: rgb(70, 70, 70);
color: white;
padding: 0.2em;
min-height: 100%;
text-align: left;
font-family: Saira, sans-serif;
}
.watch_clip_tags{
border-radius: 1em;
background-color: rgb(70, 70, 70);
color: rgb(212, 212, 212);
padding: 0.3em;
font-family: Saira, sans-serif;
}
.watch_clip_tags input {
  background-color: #575757;
  color: rgb(241, 241, 241);
  
}
.watch_clip_tags select {
  background-color: #575757;
  color: rgb(241, 241, 241);
  width: 1em;
}
.video_player{
background-color: black;    

position: relative;
 width: 100%;
}
.watch{
    background-color: rgb(43, 43, 43);
    min-height: 100%;
    width: 100%;
}
.sidebar_tools{
    color: white;
    
}
.clip{
  
cursor: pointer;
font-size: 0.8em;
border-bottom: 1px solid rgb(59, 59, 59);
padding-top: 5px;
padding-bottom: 5px;
}
.group_type{
 font-size: 0.6em; 
 color: rgb(211, 208, 208);
}
.bar_tools{
  
 color: white;
 padding: 5px;
 background-color: rgb(73, 73, 73);
 text-align: left;

}
.preview_note{
  width: 100%;
  height: auto;
}

</style>
<style>
  .videoContainer
    {
    position:absolute;
    height:100%;
    width:100%;
    overflow: hidden;
padding-top: 50%;
    }
    .videoContainer video
    {
    min-width: 100%;
    min-height: 100%;
    object-fit: fill; /*Added property*/
    }
    .video-js .vjs-control-bar,
    .video-js .vjs-big-play-button,
    .video-js .vjs-menu-button .vjs-menu-content {
    /* IE8 - has no alpha support */
    background-color: #2B333F;
    /* Opacity: 1.0 = 100%, 0.0 = 0% */
    background-color: rgba(43, 51, 63, 0);
    }

    .tools_btn {
  
        padding: 2px;
         padding-left: 5px;
         padding-right: 5px;
         border-radius: 0.3em;
         margin-left: 5px;
         cursor: pointer;
    }

      .tools_btn_active {
     background-color: rgb(54, 54, 54);
    
    }
    .vjs-control-bar{
        z-index: 10;
    }
    .video-js .vjs-big-play-button {
    left: 40% !important;
    top: 40% !important;
    width: 20%;
    
     z-index: 10;
}

.tag_table {
  border-collapse: collapse;
 width: 100% !important;
}

.tag_table th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.video-js .vjs-progress-holder {
  /* height: 2em; */

}
/* .video-js .vjs-slider{
    background-color: rgba(63, 168, 63, 0.705);

}
.video-js .vjs-play-progress{
background-color: rgba(37, 104, 37, 0.705);
} */
</style>